export const CompetitionQuestions = [
  {
    question: "Hvørjum kemur mjólkin hjá Røsku frá?",
    options: ["Geitum", "Kúm", "Seyði"],
    answer: "Kúm",
    form: "1980",
  },
  {
    question: "Hvat framleiðir Raska?",
    options: [
      "Mjólk og mjólkarúrdráttir",
      "Skip og bátar",
      "Appelsinir og súrepli",
    ],
    answer: "Mjólk og mjólkarúrdráttir",
    form: "1981",
  },
  {
    question: "Hvat er Raska?",
    options: ["Ein fuglur", "Ein ketta", "Ein kúgv"],
    answer: "Ein kúgv",
    form: "1982",
  },
  {
    question: "Hvat fært tú frá Røsku?",
    options: ["Mjólk", "Sodavatn", "Saft"],
    answer: "Mjólk",
    form: "1983",
  },
]
