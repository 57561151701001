import "styles/pages/jolakappingin-2023.scss"

import React from "react"
import { graphql } from "gatsby"
import Slider from "react-slick"

import Layout from "components/layout"
import Seo from "components/seo"

import { CompetitionQuestions } from "data/competition-2023"

const Jolakappingin2023 = ({ data }) => {
  const today = new Date()

  const show2question = new Date("12/04/2023")
  const show3question = new Date("12/11/2023")
  const show4question = new Date("12/18/2023")
  const endCompetition = new Date(new Date("12/25/2023").getTime() + 3600000)

  const slider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const slidesImagesDesktop = [
    require("assets/images/jolakappingin-2023/bg-desktop--image-1.jpg").default,
    require("assets/images/jolakappingin-2023/bg-desktop--image-2.jpg").default,
  ]

  const sliderImagesMobile = [
    require("assets/images/jolakappingin-2023/bg-mobile--image-1.jpg").default,
    require("assets/images/jolakappingin-2023/bg-mobile--image-2.jpg").default,
  ]

  return (
    <Layout isContest>
      <Seo title="JÓLAKAPPINGIN 2023" />

      <section className="jolakappingin2023">
        <div className="jolakappingin2023__desktop">
          <Slider {...slider}>
            {slidesImagesDesktop?.map((item, index) => (
              <div key={index}>
                <div
                  className="jolakappingin2023__slide"
                  style={{ backgroundImage: `url('${item}')` }}
                ></div>
              </div>
            ))}
          </Slider>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img
                  className="img-fluid"
                  src={
                    today <= endCompetition
                      ? require("assets/images/jolakappingin-2023/header--desktop.png")
                          .default
                      : require("assets/images/jolakappingin-2023/header--desktop-end.png")
                          .default
                  }
                  alt=""
                />
              </div>
              <div className="col-md-6 offset-md-1">
                {today <= endCompetition && (
                  <h4>
                    Fyri at luttaka í kappingini mást tú skanna QR-kotuna á
                    mjólkarpakkanum.
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="jolakappingin2023__mobile">
          <div className="jolakappingin2023__container">
            <Slider {...slider}>
              {sliderImagesMobile?.map((item, index) => (
                <div key={index}>
                  <div
                    className="jolakappingin2023__slide"
                    style={{ backgroundImage: `url('${item}')` }}
                  ></div>
                </div>
              ))}
            </Slider>
            <div className="jolakappingin2023__logo">
              <img
                src={
                  require("assets/images/jolakappingin-2023/logo.png").default
                }
                alt="RASKA"
              />
            </div>
            <div className="jolakappingin2023__header">
              <img
                src={
                  require("assets/images/jolakappingin-2023/header--mobile.png")
                    .default
                }
                alt="New York ella Gran Canaria"
              />
            </div>
            <div className="jolakappingin2023__description">
              <p>
                hvønn sunnudag i advent (vinnarin verður kunngjørdur mánadag)
              </p>
              <p className="highlight">
                {"Fyri at luttaka í kappingini mást tú skanna QR-kotuna á mjólkarpakkanum.".toUpperCase()}
              </p>
            </div>
            <div className="jolakappingin2023__footer">
              <p>
                Í samstarvi við{" "}
                <span className="highlight">Atlantic Airways</span>
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <div className="jolakappingin2023__tile">
                <h4>Vinnarar:</h4>
                {/* {data.wpPage.acfCompetition.competitionWinners2023 && (
                  <span style={{ marginBottom: "20px", display: "block" }}>
                    Tit sum hava svarað øðrum spurningi, sum kom ov tíðliga út,
                    vóru eisini við í kappingini.
                  </span>
                )} */}
                <ol reversed>
                  {data.wpPage.acfCompetition.competitionWinners2023 &&
                    data.wpPage.acfCompetition.competitionWinners2023.map(
                      (item, index) => (
                        <li key={index}>{item.competitionWinner}</li>
                      )
                    )}
                </ol>
              </div>
            </div>

            <div className="col-md-6 offset-md-1">
              <div className="jolakappingin2023__tile hidden-mobile">
                <h4>RØtt SVAR:</h4>

                <ol>
                  {today > show2question && (
                    <li>
                      1. {CompetitionQuestions[0]?.question}{" "}
                      <span>{CompetitionQuestions[0]?.answer}</span>
                    </li>
                  )}
                  {today > show3question && (
                    <li>
                      2. {CompetitionQuestions[1]?.question}{" "}
                      <span>{CompetitionQuestions[1]?.answer}</span>
                    </li>
                  )}
                  {today > show4question && (
                    <li>
                      3. {CompetitionQuestions[2]?.question}{" "}
                      <span>{CompetitionQuestions[2]?.answer}</span>
                    </li>
                  )}
                  {today > endCompetition && (
                    <li>
                      4. {CompetitionQuestions[3]?.question}{" "}
                      <span>{CompetitionQuestions[3]?.answer}</span>
                    </li>
                  )}
                </ol>
              </div>
            </div>
          </div>

          <div className="jolakappingin2023__info">
            TREYTIR:
            <br />
            Vinningurin er ein flogferð fyri tvey vaksin og tvey børn (0-11 ár)
            beinleiðis til New York ella Gran Canaria við Atlantic Airways.
            Uppihald er ikki við. Vinningurin kann ikki býtast um við reiðan
            pening, onnur ferðamál og skal brúkast í seinasta lagi í 2025.
            <br />
            <br />
            Tá ið ferðaseðilarnir eru bílagdir, fylgja teir vanligu
            ferðaseðlatreytunum hjá Atlantic Airways, og skulu broytingar gerast
            eftir bílegging, rindar vinnarin broytingargjald og møguligan
            prísmun.
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Jolakappingin2023

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDo4ODM=" }) {
      id
      acfCompetition {
        competitionWinners2023 {
          competitionWinner
        }
      }
    }
  }
`
